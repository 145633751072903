var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "主子查询新增",
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave("save")
          },
          "head-save-back": function ($event) {
            return _vm.headSave("back")
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        {
          staticStyle: {
            "background-color": "white",
            height: "calc(100vh - 100px)",
            padding: "20px 10px 20px 0",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "名称",
                              autocomplete: "off",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编码", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "编码",
                              autocomplete: "off",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "主服务", prop: "marketApiId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入关键词",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                              },
                              on: { change: _vm.changeFid },
                              model: {
                                value: _vm.form.marketApiId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "marketApiId", $$v)
                                },
                                expression: "form.marketApiId",
                              },
                            },
                            _vm._l(_vm.marketList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.apiName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "子服务", prop: "childrenApiIdList" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                clearable: "",
                                multiple: "",
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入关键词",
                                "remote-method": _vm.remoteMethod2,
                                loading: _vm.loading,
                              },
                              model: {
                                value: _vm.form.childrenApiIdList,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "childrenApiIdList", $$v)
                                },
                                expression: "form.childrenApiIdList",
                              },
                            },
                            _vm._l(_vm.marketChildrenList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.apiName, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }